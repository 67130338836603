.icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

[class*="eicon-"] {
    display: flex;
    justify-content: center;
    align-items: center;
}

[class*="eicon-"]::before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-background-size: contain;
}

.eicon-logout {
    font-size: 1em;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 14C5 11.6212 6.18652 9.51963 8 8.25468M16 8.2547C17.8135 9.51965 19 11.6213 19 14C19 17.866 15.866 21 12 21C10.0927 21 8.36355 20.2372 7.10101 19M12 4V12' stroke='%23000000' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
}

.eicon-logout:hover {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 14C5 11.6212 6.18652 9.51963 8 8.25468M16 8.2547C17.8135 9.51965 19 11.6213 19 14C19 17.866 15.866 21 12 21C10.0927 21 8.36355 20.2372 7.10101 19M12 4V12' stroke='rgb(104, 19, 19)' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
}

.eicon-logout:active {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 14C5 11.6212 6.18652 9.51963 8 8.25468M16 8.2547C17.8135 9.51965 19 11.6213 19 14C19 17.866 15.866 21 12 21C10.0927 21 8.36355 20.2372 7.10101 19M12 4V12' stroke='rgb(187, 98, 98)' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
}