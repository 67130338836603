@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600&family=Poiret+One&family=Poppins:wght@100;200;400;700;800&family=Roboto+Mono:wght@100;200;300;500;700&family=Source+Sans+3:wght@300;400;600;700&family=Ubuntu:wght@300;500;700&display=swap");

body,
html {
  font-family: "Ubuntu", sans-serif;
  width: 100%;
  /* height: 100%; */
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8ff;
  background-image: url(../../public/images/background2.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: brightness(105%); */
  /* -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px); */
  /* backface-visibility: hidden; */
}

.App {
  width: 90dvw;
  height: 100dvh;
  position: relative;
  margin: auto;
  cursor: default;
  /* background-color: #00c800; */
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #00640071;
}

::-webkit-scrollbar-thumb {
  background: #006400;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #004500;
}

.loginform {
  display: grid;
  margin: auto;
  grid-template-rows: repeat(3, 1fr);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  /* z-index: 5; */
}

li {
  padding: 8px;
  cursor: pointer;
}

.slicerBar {
  z-index: 5;
}

.color-box {
  border-radius: 5px;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.legend-label {
  font-size: 0.75em;
}

.guagesvg {
  width: 100%;
  height: 100%;
}
